<template>
  <!-- 查询数据 -->
  <div class="actionFrom">
    <a-spin class="isLoading" :spinning="formLoading" />
    <Theme
      :distinguish = "distinguish"
      :form="form"
      :config="form.config"
      :lookForm="true"
      @submit="saveData"
      v-if="!formLoading"
    />
  </div>
</template>
<script>
import { getForm, searchData } from '@/services/form'
import Theme from './components/Theme.vue'
export default {
  components: { Theme },
  data() {
    return {
      // 表单
      form: [],
      formLoading: false,
      company_uuid: '',
      uuid: '',
      distinguish: '',  //区分是查询表单(1)还是编辑表单(2)

      // 查询
      searchParams: {},  //查询用到的参数
      getFindDatas: [],  //存储了查询得到的数据

    }
  },
  created() {
    this.distinguish = '1'

    const { company_uuid, uuid } = this.$route.params
    this.company_uuid = company_uuid
    localStorage.setItem('company_uuid', company_uuid)
    this.uuid = uuid

    this.init()
  },
  methods: {
    // 动态拼接地址栏的方法
    connectParams(queryConfig) {
      var _str = ""
      for (var o in queryConfig) {
        if (queryConfig[o] != -1) {
          _str += o + "=" + queryConfig[o] + "&"
        }
      }
      _str = _str.substring(0, _str.length - 1)
      return _str
    },

    // 获取可用作查询的表单数据
    init() {
      this.formLoading = true
      getForm({ company_uuid: this.company_uuid, uuid: this.uuid })
        .then((res) => {
          let { data } = res
          data.elements.forEach( item => {
            item.options.required = false
          })
          data.elements = data.search  //查询表单渲染

          this.form = data
          this.formLoading = false
          document.title = data.config.title
        })
        .catch(() => {
          this.$message.error('地址错误！')
        })
    },
    
    // 表单的查询功能
    saveData(data) {
      
      this.$store.commit('datas/editClickFindBtn', true)  // 判断用户是否点击了查询按钮

      this.searchParams = data
      searchData(
        {
          company_uuid: this.company_uuid,
          uuid: this.uuid
        },
        this.searchParams
      ).then((res) => {
        
        this.getFindDatas = res.data.data
        this.$store.commit('datas/editSearchDatas', this.getFindDatas)
        
        this.$router.push({
            path: `/showdata/${this.company_uuid}/form/${this.uuid}?${this.connectParams(this.searchParams)}`,
          })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.searchData {
  box-sizing: border-box;
  padding: 10px;
}

.actionFrom {
  width: 100%;
  height: 100%;

  .content {
    min-height: 100vh;
  }
}
</style>
